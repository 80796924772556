import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import store from './store'
import 'resetcss'
import '@/assets/styles/default.styl'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import i18n from "@/lang";

const app = createApp(App)
app.use(store)
app.use(router)
app.use(store)
app.use(ElementPlus)
app.use(i18n)
window.$i18n = i18n
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.mount('#app')
