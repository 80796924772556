import {createRouter, createWebHistory} from 'vue-router'
import project from "@/router/project";
import image from "@/router/image";
import category from "@/router/category";
import train from "@/router/train";

const routes = [
    {
        path: '/',
        component: () => import('../views/layouts/LayoutMain.vue'),
        children: [
            {
                path: '/projects/:projectId/images/:id',
                name: 'WorkspaceShow',
                component: () => import('../views/workspace/WorkspaceShow.vue'),
            },
            ...project,
            ...image,
            ...category,
            ...train
        ]
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/auth/AuthLogin.vue')
    }
]

const router = createRouter({
    history: createWebHistory('/'),
    routes
})

export default router
