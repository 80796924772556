import {createI18n} from 'vue-i18n'
import en from './locales/en.json'
import zh_cn from './locales/zh_cn.json'

const i18n = createI18n({
    locale: 'zh_cn',
    legacy: false, // 如果要支持compositionAPI，此项必须设置为false;
    globalInjection: true, // 全局注册$t方法
    messages: {en, zh_cn},
})
export default i18n