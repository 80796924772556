const userStore = {
    state: () => ({
        me: null
    }),
    mutations: {
        setMe: (state, user) => {
            state.me = user
        }
    },
    actions: {},
    getters: {
        getMe: (state) => {
            return state.me
        }
    }
}

export default userStore